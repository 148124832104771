<template>
  <Modal
    :activeModal="activeModal"
    :isPersistent="true"
    :widthModal="470"
    @closeModal="closeModal">

    <v-card>
      <v-toolbar flat dark class="toolbar" :color="variables.colorPrimary">
        <h5>Atenção! Alteração de {{ typeString }} em andamento!</h5>
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      <span class="text-field">Para continuar digite:</span>
      <span class="font-weight-bold">estou-ciente-da-alteração</span>
      <v-text-field class="text-field" v-model="confirmation"></v-text-field>
      <v-card-actions >
        <v-spacer></v-spacer>
        <span></span>
        <v-btn class="br-btn" :color="variables.colorPrimary" :disabled="confirmation !== 'estou-ciente-da-alteração'" @click="confirmar">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </Modal>
</template>

<script>
import Events from '@/core/service/events'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: 'ModalIsInterior',
  mixins: [modalCadastro],
  components: { Modal: () => import('@/views/components/modal') },
  data: () => ({ 
    confirmation: '',
    type: '',
    value: ''
  }),
  mounted () { Events.$on('cadastro::openModalIsInterior', ({ type, value }) => { 
    this.type = type
    this.value = value
    this.activeModal = true 
  }) },
  computed: {
    variables: () => variables,
    typeString () {
      return this.type === 'isInterior' ? 'interior' : 'zerar saldo'
    }
  },
  methods: { 
    closeModal () { 
      this.$emit('closeModalAvaliacao', { type: this.type, value: this.value })
      this.type = ''
      this.value = ''
      this.confirmation = ''
      this.activeModal = false 
    },

    confirmar () {
      this.type = ''
      this.value = ''
      this.confirmation = ''
      this.activeModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  font-size: 20px;
  color: white;
  margin-bottom: 16px;
}

.text-field {
  padding-right: 16px;
  padding-left: 16px;
}
</style>